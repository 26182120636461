import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavbarComponent from "./NavbarComponent";
import Home from "./Home";
import Papua from "./Papua";

const App: React.FC = () => (
  <Router>
    <NavbarComponent />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/papua" element={<Papua />} />
    </Routes>
  </Router>
);

export default App;
