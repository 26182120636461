import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Element } from "react-scroll";

interface SectionProps {
  imgSrc: string;
  lines: string[];
  imgLeft: boolean;
  id: string;
}

const Section: React.FC<SectionProps> = ({ imgSrc, lines, imgLeft, id }) => (
  <Element name={id}>
    <Container className="my-5" id={id}>
      <Row>
        {imgLeft ? (
          <>
            <Col md={6}>
              <img
                src={imgSrc}
                alt=""
                className="img-fluid rounded shadow-lg slide-in-left"
                style={{ maxHeight: "80vh" }}
              />
            </Col>
            <Col
              md={6}
              style={{ lineHeight: "0.8" }}
              className="d-flex flex-column justify-content-center slide-in-left mt-5 mt-md-0"
            >
              {lines.map((line, index) => (
                <p key={index} className="lead">
                  {line}
                </p>
              ))}
            </Col>
          </>
        ) : (
          <>
            <Col
              md={6}
              style={{ lineHeight: "0.8" }}
              className="d-flex flex-column justify-content-center slide-in-right mt-5 mt-md-0"
            >
              {lines.map((line, index) => (
                <p key={index} className="lead">
                  {line}
                </p>
              ))}
            </Col>
            <Col md={6}>
              <img
                src={imgSrc}
                alt=""
                className="img-fluid rounded shadow-lg slide-in-right"
                style={{ maxHeight: "80vh" }}
              />
            </Col>
          </>
        )}
      </Row>
    </Container>
  </Element>
);

export default Section;
