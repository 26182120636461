import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const NavbarComponent: React.FC = () => (
  <Navbar style={{ background: "#000000" }} variant="dark" expand="lg">
    <Container>
      <Navbar.Brand as={NavLink} to="/" className="navbar-brand">
        Pencarian Nusantara.
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto"></Nav>
        <Nav>
          <Nav.Link as={NavLink} to="/" className="text-center">
            Eden
          </Nav.Link>
          <Nav.Link as={NavLink} to="/papua" className="text-center">
            Papua
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);

export default NavbarComponent;
