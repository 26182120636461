import React from "react";
import Section from "./Section";
import Spacer from "./Spacer";
import ScrollDownButton from "./ScrollButton";

const Papua: React.FC = () => (
  <div>
    <Spacer height="100px" />
    <Section
      imgSrc="/papua-warrior.png"
      lines={["In his spirit, the forest breathes."]}
      imgLeft={true}
      id="section1"
    />
    <Spacer height="30vh" />
    <ScrollDownButton
      targets={["section2", "section3", "section4"]}
      offset={-30}
    />
    <Section
      imgSrc="/papua-wife.png"
      lines={[
        "Her hands cradle nature's boon,",
        "Her prayer binds earth to fruit.",
      ]}
      imgLeft={true}
      id="section2"
    />
    <Spacer height="200px" />
    <Section
      imgSrc="/old-warrior.png"
      lines={[
        "He weaves tales of eon's grace,",
        "In each expression, time's echo lies.",
      ]}
      imgLeft={!true}
      id="section3"
    />
    <Spacer height="30vh" />
    <Section
      imgSrc="/sicklebell.png"
      lines={["He watches,", "Through ages,", "Powerless to his plight."]}
      imgLeft={!true}
      id="section4"
    />
  </div>
);

export default Papua;
