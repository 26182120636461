import React from "react";

interface SpacerProps {
  height: string;
  id?: string;
}

const Spacer: React.FC<SpacerProps> = ({ height, id }) => (
  <div style={{ height }} id={id}></div>
);

export default Spacer;
