import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { scroller, animateScroll as scroll } from "react-scroll";

interface ScrollButtonProps {
  targets: string[];
  offset?: number;
}

const ScrollDownButton: React.FC<ScrollButtonProps> = ({ targets, offset }) => {
  const [currentTargetIndex, setCurrentTargetIndex] = useState(0);
  const [buttonVisible, setButtonVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setButtonVisible(true);
    }, 4000); // Initially hide for 4 seconds

    return () => clearTimeout(timer);
  }, []);

  const scrollToElement = () => {
    setButtonVisible(false); // Hide on click immediately

    setTimeout(() => {
      setButtonVisible(true); // Fade in after 4 seconds
    }, 4000);

    if (currentTargetIndex < targets.length) {
      scroller.scrollTo(targets[currentTargetIndex], {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: offset,
      });
      setCurrentTargetIndex(
        (prevIndex) => (prevIndex + 1) % (targets.length + 1)
      );
    } else {
      scroll.scrollToTop({
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
      setCurrentTargetIndex(0); // Reset the index
    }
  };

  const buttonStyle: React.CSSProperties = {
    position: "fixed",
    right: 100, // numbers for pixel values
    bottom: 100, // numbers for pixel values
    background: "transparent",
    border: "1px dashed",
    opacity: buttonVisible ? 1 : 0, // Fade-in effect
    transition: "opacity 0.5s ease-in-out", // Add transition to opacity changes
    display:
      !buttonVisible && currentTargetIndex === targets.length
        ? "none"
        : "block", // Hide the button when at the last target
    zIndex: 9999, // Always on top of other elements
  };

  return (
    <Button style={buttonStyle} onClick={scrollToElement}>
      {currentTargetIndex < targets.length ? "Next" : "Back"}
    </Button>
  );
};

export default ScrollDownButton;
