import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Element } from "react-scroll";

interface CenteredSectionProps {
  imgSrc: string;
  id: string;
}

const CenteredSection: React.FC<CenteredSectionProps> = ({ imgSrc, id }) => (
  <Element name={id}>
    <Container className="my-5" id={id}>
      <Row>
        <Col md={1}></Col>
        <Col className="d-flex justify-content-center">
          <img
            src={imgSrc}
            alt=""
            className="img-fluid shadow-lg"
            style={{ maxHeight: "500px", borderRadius: "15px" }} // Max height of 300px
          />
        </Col>
      </Row>
    </Container>
  </Element>
);

export default CenteredSection;
