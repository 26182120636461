import React from "react";
import { Col } from "react-bootstrap";

interface TextComponentProps {
  lines: string[];
  id?: string;
}

const TextComponent: React.FC<TextComponentProps> = ({ lines, id }) => (
  <Col
    md={9}
    xs={{ span: 6, offset: 1 }}
    style={{ lineHeight: "0.8" }}
    className="d-flex flex-column"
    id={id}
  >
    {lines.map((line, index) => (
      <p key={index} className="lead">
        {line}
      </p>
    ))}
  </Col>
);

export default TextComponent;
