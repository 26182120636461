import React from "react";
import Section from "./Section";
import Spacer from "./Spacer";
import TextComponent from "./TextComponent";
import CenteredSection from "./CenteredSection";
import ScrollDownButton from "./ScrollButton";

const Home: React.FC = () => (
  <div>
    <Spacer height="100px" />
    <Section
      imgSrc="/africa-dreadlock.png"
      lines={[
        "In the emerald heart of a simpler time,",
        "We danced with nature, in rhythm, in rhyme.",
      ]}
      imgLeft={true}
      id="section1"
    />
    <Spacer height="30vh" />
    <ScrollDownButton
      targets={["section2", "section3", "section4"]}
      offset={-150}
    />
    <TextComponent lines={["In this tranquil theater, we once were dear."]} />
    <CenteredSection imgSrc="/africa-flower.png" id="section2" />
    <Spacer height="200px" />
    <Section
      imgSrc="/africa-beg.png"
      lines={[
        "Oh child of progress, in your eager flight,",
        "Hold dear the wisdom of the ancient night.",
      ]}
      imgLeft={!true}
      id="section3"
    />
    <Spacer height="80vh" />
    <TextComponent
      lines={[
        "The cycle of life is fragile and fair,",
        "Beware the power too great for you to bear.",
      ]}
      id="section4"
    />
    <Spacer height="20vh" />
  </div>
);

export default Home;
